import { PageProps } from '@/types/page/props';

export default <PageProps[]>[
  {
    label: 'vip_club_title',
    paths: [`/vip-club`],
    icon: 'fa-solid fa-crown',
    isProtected: true,
  },
  {
    label: 'reference_code',
    paths: ['/affiliates/panel'],
    icon: 'fa-solid fa-gift fa-swap-opacity',
    isProtected: false,
  },
];
