export const disabledOverflowPaths = [
  '-history',
  'refer',
  'affiliates',
  'bonus',
  'account',
  'security',
  'wallet',
  'verification',
  'gamblers/category',
  '/games/tag',
  '/games/providers',
  '/games/provider',
  '/terms-of-service',
  '/responsible-gambling',
  '/terms-and-conditions-affiliates',
  '/copy-trading-terms-and-conditions',
  '/sportsbook-betting-rules',
  '/sports',
  '/affiliates',
];
