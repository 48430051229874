import { CoinType } from '@starsoft/common/models';
import { CoinID } from '@/enums/coinId';

export const cryptoCoins = [
  {
    id: CoinID.USDT,
    type: CoinType.Crypto,
    name: 'Tether',
    symbol: 'USDT',
    image: 'usdt.svg',
  },
  {
    id: CoinID.USDC,
    type: CoinType.Crypto,
    name: 'USD Coin',
    symbol: 'USDC',
    image: 'usdc.svg',
  },
  {
    id: CoinID.ETH,
    type: CoinType.Crypto,
    name: 'Ethereum',
    symbol: 'ETH',
    image: 'ethereum.svg',
  },
  {
    id: CoinID.BTC,
    type: CoinType.Crypto,
    name: 'Binance Coin',
    symbol: 'BTC',
    image: 'bitcoin.svg',
  },
  {
    id: CoinID.BNB,
    type: CoinType.Crypto,
    name: 'Binance Coin',
    symbol: 'BNB',
    image: 'bnb.svg',
  },
  {
    id: CoinID.SHIB,
    type: CoinType.Crypto,
    name: 'Shiba Inu',
    symbol: 'SHIB',
    image: 'shib.svg',
  },

  {
    id: CoinID.DOGE,
    type: CoinType.Crypto,
    name: 'Dogecoin',
    symbol: 'DOGE',
    image: 'doge.svg',
  },
  {
    id: CoinID.XRP,
    type: CoinType.Crypto,
    name: 'Ripple',
    symbol: 'XRP',
    image: 'xrp.svg',
  },

  {
    id: CoinID.POL,
    type: CoinType.Crypto,
    name: 'Polygon',
    symbol: 'MATIC',
    image: 'matic.svg',
  },
];
