import { LayoutProps } from './props';
import { RootState } from '@/lib/store';
import styles from './styles.module.scss';
import { disabledOverflowPaths } from './options';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import games from '@/constants/games';
import { useIsMobile } from '@starsoft/common/hooks';
import { usePathname } from '@/hooks/usePathname';
import Footer from '@/components/Footer';
import { useSiteSealInitializer } from '@/hooks/useSiteSealInitializer';
import { useCurrentModal } from '@/hooks/modals/useCurrentModal';
import { useShowNavigation } from '@/hooks/useShowNavigation';

function Layout({ children, disabledContentStyle, disabledGap }: LayoutProps) {
  const pathname: string = usePathname();
  const collapsed = useSelector((state: RootState) => state.sideBar.collapsed);
  const disabledOverflow = useMemo(
    () => disabledOverflowPaths.some(p => pathname?.includes(p)),
    [pathname],
  );
  useSiteSealInitializer();
  const isMobile = useIsMobile();
  const showNavigation = useShowNavigation();

  const isGamePage = useMemo(
    () => Array.from(games.values()).some(g => pathname.includes(g.path)),
    [pathname],
  );
  const modal = useCurrentModal();
  const isOpenOneOfMobileDrawers = useSelector(
    (state: RootState) =>
      state.liveChat.open || state.casino.openSearch || state.sideBar.open,
  );

  function handleBlockMainScroll() {
    if (typeof window == 'undefined' || typeof document == 'undefined') {
      return;
    }

    if (!isOpenOneOfMobileDrawers && !modal) {
      document.body.classList.remove('block-scroll');
      document.body.style.overflow = 'unset';
      return;
    }

    if (!document.body.classList.contains('block-scroll')) {
      document.body.classList.add('block-scroll');
      document.body.style.overflow = 'hidden';
    }
  }

  useEffect(handleBlockMainScroll, [isOpenOneOfMobileDrawers, modal, isMobile]);

  return (
    <div
      className={`${styles.container} ${collapsed ? styles['container--collapsed'] : ''} ${disabledOverflow ? styles['container--disabledOverflow'] : ''} ${isGamePage ? styles['container--game-page'] : ''} ${!showNavigation ? styles['container--disable-all'] : ''}`}
    >
      <div
        className={`${styles.container__content} ${isOpenOneOfMobileDrawers ? styles['container__content--disable-scroll'] : ''} ${disabledContentStyle ? styles['container__content--disabledContentStyle'] : ''} ${disabledGap ? styles['container__content--disabledGap'] : ''}`}
      >
        {children}
        <div
          className={`${styles.container__footer} ${disabledContentStyle ? styles['container__footer--disabledContentStyle'] : ''}`}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Layout;
